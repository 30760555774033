import { useQuery } from 'react-query';

import { createHttpClient } from '@api/http-client';
import { END_POINTS } from '@constants/api-endpoints';
import { QUERY_KEYS } from '@constants/query-keys';
import { AlertType } from '@enums/alert';
import { showAlert } from '@lib/alert/alert-manager';
import { Workspace } from '@pages/select-workspace/types';

export const useGetWorkspace = ({ enabled }: { enabled: boolean }) => {
  const client = createHttpClient();

  const query = useQuery(
    QUERY_KEYS.WORKSPACE.GET_CURRENT_WORKSPACE,
    () => {
      return client<Workspace>({
        url: END_POINTS.WORKSPACE.BASE_WORKSPACE,
      });
    },
    {
      retry: 0,
      enabled,
      onError: (error: Error) => {
        showAlert({
          type: AlertType.ERROR,
          header: 'Failed to fetch workspace details',
          message: `${error?.message || 'Request failed'}`,
        });
      },
    },
  );

  return query;
};
