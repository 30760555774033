import { StyledDropzone } from '@components/index';
import { AlertType } from '@enums/alert';
import { showAlert } from '@lib/alert/alert-manager';
import {
  ASSET_INFO,
  UNSUPPORTED_FILE_EXT_ERROR,
} from '@pages/home-page/constants';
import { AssetType, FileExt } from '@pages/home-page/enums';
import { useBrandLibrary } from '@pages/home-page/hooks/use-brand-library';
import { useGetGroupedBrandData } from '@pages/home-page/hooks/use-get-grouped-brand-data';
import { getFileExt } from '@utils/file';

import BrandLibraryDropzone from './BrandLibraryDropZone';

const BrandLibrary = () => {
  const groupedBrandData = useGetGroupedBrandData();

  const { handleUploadFiles } = useBrandLibrary();

  const handleUpload = (files: File[]): void => {
    if (!files?.length) {
      return;
    }
    files.map(file => {
      let assetType;
      const fileType = getFileExt(file.name);

      if (ASSET_INFO[AssetType.LOGO].fileTypes.includes(fileType))
        assetType = AssetType.LOGO;
      else if (ASSET_INFO[AssetType.FONT].fileTypes.includes(fileType))
        assetType = AssetType.FONT;
      else if (ASSET_INFO[AssetType.VIDEO].fileTypes.includes(fileType))
        assetType = AssetType.VIDEO;
      else if (ASSET_INFO[AssetType.PRODUCT].fileTypes.includes(fileType))
        assetType = AssetType.PRODUCT;
      else assetType = AssetType.RAW_FILE;

      handleUploadFiles([file], assetType);
    });
  };

  return (
    <div className="flex flex-col w-full h-screen border-r border-gray-6 pt-4">
      <div className="h-[65px] flex justify-between items-center pl-4 border-b border-gray-6">
        <span className="text-2xl font-semibold">Brand Library</span>
      </div>
      <div className="h-[calc(100vh-65px)] flex flex-col p-5 overflow-auto">
        <StyledDropzone
          onFilesDrop={handleUpload}
          accept={FileExt.BRAND_FILES}
          onFilesReject={() =>
            showAlert({
              type: AlertType.ERROR,
              message: `${UNSUPPORTED_FILE_EXT_ERROR}${FileExt.BRAND_FILES}`,
            })
          }
        >
          <BrandLibraryDropzone
            handleUpload={handleUpload}
            groupedBrandData={groupedBrandData}
          />
        </StyledDropzone>
      </div>
    </div>
  );
};

export default BrandLibrary;
