import { Slide, toast, ToastContainer } from 'react-toastify';

import { Alert } from '@components/index';
import { AlertType } from '@enums/alert';

export const AlertsContainer = ToastContainer;

export type Options = {
  header?: string;
  message: string;
  type?: AlertType;
  className?: string;
};

export const showAlert = (options: Options) => {
  const { message, type = AlertType.SUCCESS, className = '' } = options;

  toast(<Alert type={type} message={message} />, {
    toastId: message,
    position: 'top-center',
    autoClose: 3000,
    hideProgressBar: true,
    transition: Slide,
    className: className,
  });
};
