import { useEffect, useState } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { ChatRole, Language } from '@pages/home-page/enums';
import {
  chatLanguage,
  chatList,
  chatLoaderState,
  selectedChatSuggestion,
} from '@store/atoms/chat';

import { useChatRequest } from './use-chat-request';
import { useChatResponse } from './use-chat-response';

export const useChatGeneration = () => {
  const chatSuggestion = useRecoilValue(selectedChatSuggestion);
  const selectedLanguage = useRecoilValue(chatLanguage);

  const setChatList = useSetRecoilState(chatList);
  const setIsGeneratingChat = useSetRecoilState(chatLoaderState);

  const { mutateAsync: sendChatRequest } = useChatRequest();

  const [sessionId, setSessionId] = useState('');

  useEffect(() => {
    if (chatSuggestion) onGenerateChat(chatSuggestion, selectedLanguage.id);
  }, [chatSuggestion]);

  const onGenerateChat = async (chatInput: string, language: Language) => {
    setIsGeneratingChat(true);

    setChatList(prevChatList => [
      ...prevChatList,
      {
        role: ChatRole.HUMAN,
        data: {
          response: chatInput,
        },
      },
    ]);

    const response = await sendChatRequest({
      humanMessage: chatInput,
      language,
    });

    setSessionId(response?.session_id);
  };

  useChatResponse({
    session_id: sessionId,
    onChatResponse: () => {
      setIsGeneratingChat(false);
    },
  });

  return {
    onGenerateChat,
  };
};
