export const END_POINTS = {
  AUTH: {
    LOGIN: '/auth/login',
    SSO_LOGIN: '/auth/sso_config',
    FORGOT_PASSWORD: '/auth/forgot',
    RESET_PASSWORD: '/auth/reset',
    LOGOUT: '/auth/logout',
  },
  USER: {
    GET_USER_DETAILS: '/user',
    GET_USER_LIST: (workspaceId: number) => `/client/${workspaceId}/users`,
    INVITE_USER: (workspaceId: number) => `client/${workspaceId}/invite`,
  },
  WORKSPACE: {
    LIST_WORKSPACE: '/client/list',
    HIERARCHY: '/client/v1/hierarchy',
    SELECT_CLIENT: (userId: number | null, clientUuid: string) =>
      `/user/${userId}/client/${clientUuid}/select`,
    BASE_WORKSPACE: '/client',
  },
  BRAND_LIBRARY: {
    GET_PRESIGNED_URL: '/upload/presignedurl',
    CREATE_ASSET: '/asset',
    LIST_ASSETS: '/assets',
    DELETE_ASSET: (assetId: number) => `/asset/${assetId}`,
  },
  ROLE: {
    LIST_ROLES: '/roles',
  },
  CHAT: {
    CHAT_REQUEST: '/chat-proxy/api/chat',
  },
};
