import { useState } from 'react';

import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { usePopper } from 'react-popper';
import { Link } from 'react-router-dom';

import { Settings } from '@assets/icons';
import { Button } from '@components/index';
import { useLogout } from '@hooks/use-logout';
import RoutesPath from '@routes/constants';

const SettingsOption = () => {
  const { mutate: logout } = useLogout();

  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>();
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'right-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  return (
    <Popover>
      {() => (
        <>
          <PopoverButton
            ref={
              setReferenceElement as (
                instance: HTMLButtonElement | null,
              ) => void
            }
            as="div"
            className="cursor-pointer"
          >
            <div className="flex flex-row gap-2">
              <Settings />
              <span className="text-sm font-semibold">Settings</span>
            </div>
          </PopoverButton>
          <PopoverPanel
            className="bg-white w-64 shadow-2xl rounded py-2"
            ref={element => setPopperElement(element as HTMLDivElement | null)}
            style={styles.popper}
            {...attributes.popper}
          >
            <PopoverButton as="div">
              <>
                <div className="text-dark-1 text-sm flex h-10 px-3  cursor-pointer items-center hover:bg-yellow-2">
                  Workspaces
                </div>
                <Link to={RoutesPath.MANAGE_TEAM}>
                  <div className="text-dark-1 text-sm flex h-10 px-3  cursor-pointer items-center hover:bg-yellow-2">
                    Manage Team
                  </div>
                </Link>
                <div className="px-3 py-2">
                  <Button
                    label="Log out"
                    variant="error"
                    onClick={() => logout()}
                  />
                </div>
              </>
            </PopoverButton>
          </PopoverPanel>
        </>
      )}
    </Popover>
  );
};

export default SettingsOption;
