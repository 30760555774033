import { getFileExt, getFileName, getSanitizedFileName } from '@utils/file';

export const processFiles = (
  acceptedFiles: File[],
  onFilesDrop: (files: File[]) => void,
) => {
  const sanitisedFiles = acceptedFiles.map((file: File) => {
    const fileName = getFileName(file.name);
    const ext = getFileExt(file.name);
    const sanitisedName = `${getSanitizedFileName(
      fileName,
    )}.${ext.toLowerCase()}`;

    return new File([file], sanitisedName, {
      type: file.type,
    });
  });

  onFilesDrop(sanitisedFiles);
};
