import { FC, Fragment } from 'react';

import { EllipsisGrid } from '@assets/icons';
import { MoreOptions } from '@components/index';
import { AssetType, KnowledgeCategory } from '@pages/home-page/enums';
import { DataItem } from '@pages/home-page/types';
import { toDateFormat } from '@utils/date';

interface KnowledgeItemsProps {
  knowledgeDataItems: {
    [key in KnowledgeCategory]?: DataItem[];
  };
  workspace: string;
  handleDelete: (id: number, assetType: AssetType) => void;
}

const KnowledgeItems: FC<KnowledgeItemsProps> = ({
  knowledgeDataItems,
  workspace,
  handleDelete,
}) => {
  return (
    <>
      {Object.entries(knowledgeDataItems).map(([knowledgeCategory, items]) => {
        return (
          <Fragment key={knowledgeCategory}>
            <div className="my-4 px-11 w-full uppercase text-sm">
              {knowledgeCategory}
            </div>
            {items.map((item: DataItem) => (
              <tr key={item.id}>
                <td className="px-2" colSpan={4}>
                  <div className="w-full flex flex-1 flex-row rounded border border-gray-2 p-2 items-center mb-2">
                    <div className="w-[48%] flex flex-row gap-4 items-center">
                      <EllipsisGrid className="flex-shrink-0" />
                      <div className="text-sm pr-4">{item.details}</div>
                    </div>
                    <div className="w-[20%] text-sm">{workspace}</div>
                    <div className="w-[15%] text-sm pl-5">{item.createdBy}</div>
                    <div className="w-[20%] text-sm pl-10">
                      {toDateFormat(item.createdAt) || '-'}
                    </div>
                    <div className="flex flex-1 justify-end">
                      <MoreOptions
                        onClickDelete={() =>
                          handleDelete(item.id, AssetType.KNOWLEDGE)
                        }
                      />
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </Fragment>
        );
      })}
    </>
  );
};

export default KnowledgeItems;
