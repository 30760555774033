import { useQuery } from 'react-query';

import { createHttpClient } from '@api/http-client';
import { END_POINTS } from '@constants/api-endpoints';
import { QUERY_KEYS } from '@constants/query-keys';
import { AlertType } from '@enums/alert';
import { showAlert } from '@lib/alert/alert-manager';

import { GetUsersResponse } from '../types';

export const useGetUsers = (workspaceId: number, enabled: boolean) => {
  const client = createHttpClient();

  const query = useQuery(
    QUERY_KEYS.USER.USER_LIST,
    () =>
      client<GetUsersResponse>({
        url: END_POINTS.USER.GET_USER_LIST(workspaceId),
      }),

    {
      enabled,
      onError: (error: Error) => {
        showAlert({
          type: AlertType.ERROR,
          header: 'Failed to get users',
          message: `${error?.message || 'Request failed'}`,
        });
      },
    },
  );

  return query;
};
