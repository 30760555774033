import { useUploadFileToR2 } from '@hooks/use-upload-file-to-r2';

import { useCreateBrandAsset } from './use-create-brand-asset';
import { AssetType, RawFileCategory } from '../enums';
import { CreateBrandAssetRequest } from '../types';

export const useBrandLibrary = () => {
  const uploadFileToR2 = useUploadFileToR2();

  const { mutateAsync: createBrandAsset } = useCreateBrandAsset();

  const createAssets = async (
    uploadedData: {
      s3Bucket: string;
      s3Key: string;
    },
    fileName: string,
    assetType: AssetType,
  ) => {
    const brandAsset: CreateBrandAssetRequest = {
      assetType: assetType,
      fileName,
      s3Key: uploadedData.s3Key,
      s3Bucket: uploadedData.s3Bucket,
    };
    if (assetType === AssetType.RAW_FILE) {
      brandAsset['metadata'] = {
        type: RawFileCategory.BRAND_BOOK,
      };
    }

    await createBrandAsset(brandAsset);
  };

  const handleUploadFiles = async (
    uploadedFiles: File[],
    assetType: AssetType,
  ) => {
    const fileName = uploadedFiles[0]?.name;
    const response = await uploadFileToR2({
      file: uploadedFiles[0],
      filename: fileName,
    });
    createAssets(response, fileName, assetType);
  };

  return {
    handleUploadFiles,
  };
};
