import { MainLayout } from '@layout/index';

import ManageTeamTable from './components/ManageTeamTable';
const ManageTeam = () => {
  return (
    <MainLayout>
      <div className="w-full flex flex-col px-6">
        <div className="w-full h-[122px] py-12 px-11">
          <div className="text-2xl font-semibold">Manage Team</div>
        </div>
        <ManageTeamTable />
      </div>
    </MainLayout>
  );
};

export default ManageTeam;
