import { ChatResponse } from '@hooks/types';
import { usePusher } from '@hooks/use-pusher';
import { ChatRole } from '@pages/home-page/enums';
import { chatList } from '@store/atoms/chat';
import { userProfile } from '@store/atoms/user';
import { workspace } from '@store/atoms/workspace';
import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

interface SoketiMessage<T> {
  content: T;
  status_code: number;
  error?: any;
}

interface SoketiResponse<T> {
  eventName: string;
  message: SoketiMessage<T>;
}

export const useChatResponse = ({
  session_id,
  onChatResponse,
}: {
  session_id: string;
  onChatResponse: () => void;
}) => {
  const user = useRecoilValue(userProfile);
  const currentWorkspace = useRecoilValue(workspace);

  const setChatList = useSetRecoilState(chatList);

  const { bindEvent } = usePusher(currentWorkspace.id);

  useEffect(
    function chatResponseEvent() {
      if (!user.id || !session_id) return;
      const soketiEvent = `CHAT_${user.id}_${session_id}:RESPONSE`;

      bindEvent(soketiEvent, (data: SoketiResponse<ChatResponse>) => {
        const chatResponse = data?.message?.content;

        setChatList(prevChatList => [
          ...prevChatList,
          {
            role: ChatRole.AI,
            data: chatResponse,
          },
        ]);

        onChatResponse();
      });
    },
    [session_id, user.id],
  );
};
