import { isNil, isObject, isString } from 'lodash';

import { DataItem } from './types';

export const doesValueExist = (value: string, searchTerm: string) => {
  return value.toLowerCase().includes(searchTerm.toLowerCase());
};

export const searchObject = (obj: DataItem, searchTerm: string) => {
  for (const value of Object.values(obj)) {
    // Searching in nested objects
    if (isObject(value) && !isNil(value)) {
      if (searchObject(value, searchTerm)) {
        return true;
      }
    } else if (isString(value) && doesValueExist(value, searchTerm)) {
      return true;
    }
  }

  return false;
};

export const filterResults = (searchTerm: string, assets: DataItem[]) => {
  if (searchTerm) {
    const filteredResults = assets?.filter((item: DataItem) =>
      searchObject(item, searchTerm),
    );

    return filteredResults;
  }

  return assets;
};
