import { HttpMethod } from '@api/enums';
import { createHttpClient } from '@api/http-client';
import { END_POINTS } from '@constants/api-endpoints';

interface PreSignedUrlResponse {
  preSignedUrl: string;
  s3Bucket: string;
  s3Key: string;
}

export const useUploadFileToR2 = () => {
  const client = createHttpClient();
  const clientWithoutCreds = createHttpClient({ allowWithCredentials: false });

  const uploadFileToR2 = async ({
    filename,
    file,
  }: {
    filename: string;
    file: File;
  }) => {
    const { preSignedUrl, s3Bucket, s3Key } =
      await client<PreSignedUrlResponse>({
        url: END_POINTS.BRAND_LIBRARY.GET_PRESIGNED_URL,
        params: { filename },
      });

    await clientWithoutCreds({
      url: preSignedUrl,
      method: HttpMethod.PUT,
      data: file,
    });

    return {
      s3Bucket,
      s3Key,
    };
  };

  return uploadFileToR2;
};
