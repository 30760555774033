export enum Language {
  ENGLISH = 'en',
  ARABIC = 'ar',
  BENGALI = 'bn',
  BOSNIAN = 'bs',
  CATALAN = 'ca',
  CHINESE = 'cn',
  TRADITIONAL_CHINESE = 'zh',
  CROATIAN = 'hr',
  CZECH = 'cs',
  DANISH = 'da',
  DUTCH = 'nl',
  FINNISH = 'fi',
  FRENCH = 'fr',
  GERMAN = 'de',
  GREEK = 'el',
  GUJARATI = 'gu',
  HEBREW = 'he',
  HINDI = 'hi',
  HUNGARIAN = 'hu',
  INDONESIA = 'id',
  ITALIAN = 'it',
  JAPANESE = 'jp',
  KANNADA = 'kn',
  KOREAN = 'kr',
  MALAY = 'ms',
  MALAYALAM = 'ml',
  MARATHI = 'mr',
  NORWEGIAN = 'no',
  POLISH = 'pl',
  PORTUGUESE = 'pt',
  PUNJABI = 'pa',
  ROMANIAN = 'ro',
  RUSSIAN = 'ru',
  SERBIAN = 'sr',
  SLOVAKIAN = 'sk',
  SLOVENIAN = 'sl',
  SPANISH = 'es',
  SWEDISH = 'sv',
  TAGALOG = 'tl',
  TAMIL = 'ta',
  TELUGU = 'te',
  THAI = 'th',
  TURKISH = 'tr',
  UKRAINIAN = 'uk',
  VIETNAMESE = 'vn',
}

export enum FileExt {
  BRAND_FILES = '.png, .jpg, .jpeg, .ttf, .otf, .mp3, .wav, .pdf, .gif, .docx, .pptx, .doc, .ppt',
  RAW_FILE = '.pdf, .docx, .pptx, .doc, .ppt',
}

export enum AssetType {
  RAW_FILE = 'RAW_FILE',
  LOGO = 'LOGO',
  FONT = 'FONT',
  COLORS = 'COLORS',
  VIDEO = 'VIDEO',
  PRODUCT = 'PRODUCT',
  KNOWLEDGE = 'KNOWLEDGE',
}

export enum RawFileCategory {
  BRAND_BOOK = 'BRAND_BOOK',
}

export enum KnowledgeCategory {
  DIGITAL_MANDATORIES = 'DIGITAL_MANDATORIES',
  SEO_BEST_PRACTICES = 'SEO_BEST_PRACTICES',
  BRAND_COMMUNICATION_IDEA = 'BRAND_COMMUNICATION_IDEA',
  BRAND_PERSONALITY = 'BRAND_PERSONALITY',
  BRAND_AMBITION = 'BRAND_AMBITION',
  BRAND_BENEFITS = 'BRAND_BENEFITS',
  BRAND_CONSUMER = 'BRAND_CONSUMER',
  BRAND_HISTORY = 'BRAND_HISTORY',
  BRAND_LOVE_KEY = 'BRAND_LOVE_KEY',
  BRAND_PURPOSE = 'BRAND_PURPOSE',
  PRODUCT_DESCRIPTION = 'PRODUCT_DESCRIPTION',
}

export enum ChatRole {
  HUMAN = 'human',
  AI = 'ai',
}
