import { AxiosError } from 'axios';
import { isNil } from 'lodash';

import { ErrorType } from '@enums/error';

export const getErrorMessage = (error: AxiosError) => {
  const status = error.response?.status;
  const message = error.response?.data?.message || error.response?.data?.msg;

  if (status && status === 401 && message === ErrorType.UNAUTHORIZED) {
    return ErrorType.APP_SESSION_EXPIRED;
  }

  const data = error?.response?.data;
  if (!isNil(data?.msg)) {
    return data.msg;
  }
  if (!isNil(data?.message)) {
    return data.message;
  }

  return ErrorType.DEFAULT;
};
