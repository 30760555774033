import { useRef, useState } from 'react';

import { isEmpty } from 'lodash';
import { useRecoilState } from 'recoil';

import { LANGUAGE_OPTIONS } from '@pages/home-page/constants';
import { chatLanguage } from '@store/atoms/chat';

import { ChatLanguageSelect } from './ChatLanguageSelect';
import { useChatGeneration } from './hooks/use-chat-generation';

const ChatBox = () => {
  const { onGenerateChat } = useChatGeneration();

  const chatInputRef = useRef<HTMLTextAreaElement>(null);
  const [isEmptyChatInput, setIsEmptyChatInput] = useState(true);
  const [selectedLanguage, setSelectedLanguage] = useRecoilState(chatLanguage);

  const chatInput = chatInputRef.current;

  const onSendChatRequest = () => {
    if (chatInput && chatInput.value.trim()) {
      onGenerateChat(chatInput.value, selectedLanguage.id);
      chatInput.value = '';
      setIsEmptyChatInput(true);
    }
  };

  return (
    <div className="p-4 space-y-4 shadow-lg border-t">
      <div className="flex flex-col gap-3">
        <ChatLanguageSelect
          options={LANGUAGE_OPTIONS}
          selectedLanguage={selectedLanguage}
          onLanguageSelect={language => setSelectedLanguage(language)}
        />
        <div className="h-fit flex justify-between items-center space-x-2 px-2 py-3 border border-gray-4 rounded">
          <textarea
            ref={chatInputRef}
            className="w-full h-7 max-h-40 bg-transparent text-sm placeholder-gray-5 focus:outline-none resize-none"
            placeholder="Chat with me"
            onChange={e => {
              if (isEmpty(e.currentTarget.value.trim())) {
                return setIsEmptyChatInput(true);
              }

              setIsEmptyChatInput(false);
            }}
            onKeyDown={e => {
              if (e.key !== 'Enter' || e.shiftKey) return;

              e.preventDefault();

              if (!isEmptyChatInput) {
                onSendChatRequest();
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ChatBox;
