import { Fragment, useState } from 'react';

import { Transition } from '@headlessui/react';

import { Popper } from '@components/index';

export const CopyInviteLink = ({ inviteLink }: { inviteLink: string }) => {
  const [isLinkClicked, setIsLinkClicked] = useState(false);

  const getLinkCopied = (link: string) => {
    setIsLinkClicked(true);
    navigator.clipboard.writeText(link);
  };

  const RefComponent = () => (
    <span
      onMouseLeave={() => setIsLinkClicked(false)}
      onClick={() => inviteLink && getLinkCopied(inviteLink)}
      className="text-yellow-500 font-semibold cursor-pointer"
    >
      Copy invite link
    </span>
  );

  return (
    <Popper
      placement="bottom-end"
      PopComponent={({ popperElRef, setPopperElement }: any) => (
        <Transition
          afterLeave={() => {
            setPopperElement(null);
          }}
          as={Fragment}
          beforeEnter={() => setPopperElement(popperElRef.current)}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          show={isLinkClicked}
        >
          <span>
            {isLinkClicked && (
              <div className="flex border border-gray-6 items-center pl-2 pr-2 py-1 bg-white rounded space-x-2 shadow-2">
                <p>Link copied to clipboard</p>
              </div>
            )}
            ;
          </span>
        </Transition>
      )}
      RefComponent={() => <RefComponent />}
    />
  );
};
