import { Dispatch, FC, SetStateAction } from 'react';

import { Add, AngleDown, AngleForward } from '@assets/icons';
import { Button, ConditionalWrapper, Dropzone } from '@components/index';
import { AssetType, FileExt } from '@pages/home-page/enums';

interface CollapsibleAssetWrapperProps {
  isCollapsed: boolean;
  setIsCollapsed: Dispatch<SetStateAction<boolean>>;
  header: string;
  assetType: AssetType;
}
export const CollapsibleAssetWrapper: FC<CollapsibleAssetWrapperProps> = ({
  isCollapsed,
  setIsCollapsed,
  header,
  assetType,
}) => {
  const handleCollapse = () => {
    setIsCollapsed(prev => !prev);
  };

  return (
    <tr className="w-full">
      <td colSpan={4}>
        <div className="mb-0.5">
          <div className="w-full border-t flex justify-between px-4">
            <button
              className="flex space-x-2 h-14 items-center w-full"
              onClick={handleCollapse}
            >
              <div className="flex space-x-3 items-center">
                {isCollapsed ? (
                  <AngleForward className="w-4 h-4" />
                ) : (
                  <AngleDown className="w-4 h-4" />
                )}
                <div className="text-sm">{header}</div>
              </div>
            </button>
            <div className="flex items-center">
              <ConditionalWrapper
                condition={assetType === AssetType.RAW_FILE}
                wrapper={(_children: React.ReactNode) => (
                  <Dropzone
                    onFilesDrop={() => {}}
                    multiple={true}
                    accept={FileExt.RAW_FILE}
                  >
                    {_children}
                  </Dropzone>
                )}
              >
                <Button
                  label="Add new"
                  {...(assetType !== AssetType.RAW_FILE && {
                    onClick: e => {
                      e.stopPropagation();
                    },
                  })}
                  IconLeftComponent={Add}
                  classOverride="border-gray-4 px-2 text-sm text-gray-3 h-8"
                />
              </ConditionalWrapper>
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
};
