import { FC, Fragment, ReactNode } from 'react';

import { Dialog, Transition, TransitionChild } from '@headlessui/react';

import { Cross } from '@assets/icons';
import Button from '@components/button/Button';

export interface ModalProps {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  classOverride?: string;
}

const Modal: FC<ModalProps> = ({
  children,
  isOpen,
  onClose,
  classOverride = '',
}) => (
  <Transition appear as={Fragment} show={isOpen}>
    <Dialog
      as="div"
      className="fixed inset-0 overflow-y-auto z-50"
      open={isOpen}
      onClose={onClose}
    >
      <div className="min-h-screen flex justify-center items-center">
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50" />
        </TransitionChild>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div
            className={`relative max-w-screen max-h-[90vh] transition-all transform bg-white rounded-2xl ${classOverride}`}
          >
            <Button
              variant="text"
              IconLeftComponent={Cross}
              onClick={onClose}
              classOverride="absolute right-5 top-5 z-10"
            />
            {children}
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </Transition>
);

export default Modal;
