import { FC, useMemo } from 'react';

import { Navigate } from 'react-router-dom';

import { isLoggedInUser } from '@utils/auth';

import RoutesPath from './constants';

const ProtectedRoute: FC<{ children: React.ReactNode }> = ({ children }) => {
  const isAuthenticated = useMemo(() => isLoggedInUser(), []);

  if (!isAuthenticated) {
    return <Navigate replace to={RoutesPath.LOGIN} />;
  } else {
    return <>{children}</>;
  }
};

export default ProtectedRoute;
