import { useQuery } from 'react-query';

import { createHttpClient } from '@api/http-client';
import { END_POINTS } from '@constants/api-endpoints';
import { QUERY_KEYS } from '@constants/query-keys';

import { ClientPathResponse } from '../types';

export const useWorkspaceHierarchy = ({
  clientId,
  enabled = true,
}: {
  clientId: number | null;
  enabled: boolean;
}) => {
  const client = createHttpClient();

  const query = useQuery(
    [QUERY_KEYS.WORKSPACE.CLIENT_HIERARCHY, clientId],
    () =>
      client<ClientPathResponse>({
        url: END_POINTS.WORKSPACE.HIERARCHY,
        params: { clientId },
      }),
    {
      enabled,
    },
  );

  return query;
};
