import { Fragment, useState } from 'react';

import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import { AlertType } from '@enums/alert';
import { ErrorType } from '@enums/error';
import { AlertsContainer, showAlert } from '@lib/alert/alert-manager';
import 'react-toastify/dist/ReactToastify.css';

import RoutesPath from '@routes/constants';
import { LocalStorageKeys } from '@utils/auth';

import Routes from './routes';

import './App.css';

const onError = (error: any) => {
  const errMsg = error.message;

  if (errMsg === ErrorType.APP_SESSION_EXPIRED) {
    window.location.href = window.location.origin + RoutesPath.LOGIN;
    localStorage.removeItem(LocalStorageKeys.IS_LOGGED_IN);

    return;
  }

  showAlert({
    type: AlertType.ERROR,
    message: errMsg,
  });
};

const App = () => {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            onError,
          },
        },
      }),
  );

  return (
    <RecoilRoot>
      <AlertsContainer />
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </QueryClientProvider>
    </RecoilRoot>
  );
};

createRoot(document.getElementById('root') as HTMLElement).render(
  <Fragment>
    <App />
  </Fragment>,
);
