import { FC, useState } from 'react';

import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { usePopper } from 'react-popper';

import { Ellipsis, Trash } from '@assets/icons';

interface MoreOptionsProps {
  onClickDelete: () => void;
}
const MoreOptions: FC<MoreOptionsProps> = ({ onClickDelete }) => {
  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>();
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-end',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  return (
    <Popover>
      {() => (
        <>
          <PopoverButton
            ref={
              setReferenceElement as (
                instance: HTMLButtonElement | null,
              ) => void
            }
            as="div"
            className="flex justify-center items-center w-10 h-10 rounded cursor-pointer focus:outline-none"
          >
            <Ellipsis />
          </PopoverButton>
          <PopoverPanel
            className="w-28 py-2 bg-white rounded-md shadow-2xl z-10"
            ref={element => setPopperElement(element as HTMLDivElement | null)}
            style={styles.popper}
            {...attributes.popper}
          >
            <PopoverButton as="div">
              <div
                className="flex items-center space-x-2 w-full p-2 group cursor-pointer hover:bg-yellow-2"
                onClick={onClickDelete}
              >
                <Trash className="text-dark-1 h-4 w-4" />
                <div className="text-dark-1 text-sm">Delete</div>
              </div>
            </PopoverButton>
          </PopoverPanel>
        </>
      )}
    </Popover>
  );
};

export default MoreOptions;
