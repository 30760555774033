import { useEffect, useState } from 'react';

import { AssetType } from '@pages/home-page/enums';
import { AssetToDelete, DataItem } from '@pages/home-page/types';

import AssetDataItems from './AssetDataItems';
import { CollapsibleAssetWrapper } from './CollapsibleAssetWrapper';
import DeleteAssetModal from '../delete-asset-modal/DeleteAssetModal';

type CollapsibleAssetItemProps = {
  assetItem: {
    id: AssetType;
    header: string;
    tooltipMessage: string;
    dataItems: DataItem[];
    imageClassName?: string;
  };
  isAllItemsCollpased: boolean;
  isLoading: boolean;
};

const CollapsibleAssetItem = ({
  assetItem,
  isAllItemsCollpased,
  isLoading,
}: CollapsibleAssetItemProps) => {
  const [isCollapsed, setIsCollapsed] = useState(isAllItemsCollpased);
  const [assetToDelete, setAssetToDelete] = useState<AssetToDelete>({
    id: null,
    assetType: null,
  });

  useEffect(() => {
    setIsCollapsed(isAllItemsCollpased);
  }, [isAllItemsCollpased]);

  const handleDelete = (id: number, assetType: AssetType) => {
    setAssetToDelete({
      id: id,
      assetType: assetType,
    });
  };

  const onDeleteModalClose = () => {
    setAssetToDelete({
      id: null,
      assetType: null,
    });
  };

  return (
    <>
      <CollapsibleAssetWrapper
        assetType={assetItem?.id}
        header={assetItem.header}
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
      />
      {!isCollapsed && (
        <AssetDataItems
          dataItems={assetItem?.dataItems}
          isLoading={isLoading}
          imageClassName={assetItem?.imageClassName || ''}
          handleDelete={(id, assetType) => handleDelete(id, assetType)}
        />
      )}
      {assetToDelete.id && assetToDelete.assetType && (
        <DeleteAssetModal
          assetToDelete={assetToDelete}
          onCloseModal={onDeleteModalClose}
        />
      )}
    </>
  );
};

export default CollapsibleAssetItem;
