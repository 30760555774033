import { FC } from 'react';

import { CircleCheck, Error } from '@assets/icons';
import { AlertType } from '@enums/alert';

export interface AlertProps {
  type: AlertType;
  message: string;
}

const Alert: FC<AlertProps> = ({ type = AlertType.SUCCESS, message = '' }) => {
  return (
    <div className="flex items-start space-x-[6px]">
      {type === AlertType.ERROR && (
        <Error className="flex-none h-5 w-5 text-red-1" />
      )}
      {type === AlertType.SUCCESS && (
        <CircleCheck className="flex-none h-5 w-5 text-green-1" />
      )}
      <p className="min-w-0 break-words">{message}</p>
    </div>
  );
};

export default Alert;
