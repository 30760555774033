import { FC } from 'react';

import { Dropzone, ProgressBar } from '@components/index';
import { AlertType } from '@enums/alert';
import { showAlert } from '@lib/alert/alert-manager';
import { UNSUPPORTED_FILE_EXT_ERROR } from '@pages/home-page/constants';
import { FileExt } from '@pages/home-page/enums';

import AssetIdsButton from './AssetIdsButton';

interface BrandUploaderProps {
  isImportingBrandKnowledge?: boolean;
  progress?: number;
  onUploadFiles?: (files: File[]) => void;
}

const BrandUploader: FC<BrandUploaderProps> = ({
  isImportingBrandKnowledge = false,
  progress = 0,
  onUploadFiles,
}) => {
  const currentProgress = progress && progress !== 100;

  const getDescription = () => {
    if (isImportingBrandKnowledge) {
      return 'Brand knowledge extraction is in progress';
    }

    return (
      <div className="text-center text-sm mt-1">
        You can upload brand documents as PDF/DOCX to extract brand information.
        <br />
        This info will be used to generate content for your brand library.
      </div>
    );
  };

  return (
    <div className="w-full h-[179px] border-dashed border rounded border-gray-4 flex flex-col items-center justify-center p-5">
      <div className="flex text-lg">
        Drag and drop or &nbsp;
        <Dropzone
          onFilesDrop={onUploadFiles}
          multiple
          accept={FileExt.BRAND_FILES}
          onFilesReject={() =>
            showAlert({
              type: AlertType.ERROR,
              message: `${UNSUPPORTED_FILE_EXT_ERROR}${FileExt.BRAND_FILES}`,
            })
          }
        >
          <u className="cursor-pointer text-yellow-3"> upload a file</u>
        </Dropzone>
        &nbsp; on this page
      </div>
      <div className="text-base text-gray-5 mb-3">{getDescription()}</div>
      {currentProgress || isImportingBrandKnowledge ? (
        <div className="flex flex-col items-center gap-4 w-[400px] py-4">
          <ProgressBar progress={progress} />
          <div className="flex text-base text-gray-5">
            Your files are being processed ({progress ?? 0})%
          </div>
        </div>
      ) : (
        <div className="flex gap-1.5 justify-center">
          <AssetIdsButton />
        </div>
      )}
    </div>
  );
};

export default BrandUploader;
