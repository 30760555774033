import { useRecoilValue } from 'recoil';

import { usePusher } from '@hooks/use-pusher';
import { MainLayout } from '@layout/index';
import { workspace } from '@store/atoms/workspace';

import BrandLibrary from './components/brand-library/BrandLibrary';
import ChatPanel from './components/chat-panel/ChatPanel';

const HomePage = () => {
  const currentWorkspace = useRecoilValue(workspace);

  usePusher(currentWorkspace.id);

  return (
    <MainLayout>
      <ChatPanel />
      <BrandLibrary />
    </MainLayout>
  );
};

export default HomePage;
